.cards,
.cards * {
}
.cards {
  background: var(--container-background-color, #f7f7f7);
  border-radius: 2px;
  flex-direction: column;
  gap: 0px;
  width: 247px;
  height: 246px;
  position: "fixed";
    top: "0";
    left: "0";
  box-shadow: var(
    --shadow-floating-box-shadow,
    0px 4px 16px 0px rgba(0, 0, 0, 0.3)
  );
  overflow: hidden;
}
.top-area {
  align-self: stretch;
  flex-shrink: 0;
  height: 48px;
  position: relative;
}
.right-buttons {
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: center;
  justify-content: flex-start;
  height: 39px;
  position: absolute;
  right: 0px;
  top: calc(50% - 20px);
}
.expand {
  padding: 4px;
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 48px;
  position: relative;
  transform-origin: 0 0;
  transform: rotate(0deg) scale(1, 1);
}
.leading-icon {
  flex-shrink: 0;
  position: relative;
  overflow: visible;
}
.border-bottom {
  background: var(--border-divider-color, rgba(0, 0, 0, 0.08));
  border-radius: 1px;
  height: 1px;
  position: absolute;
  right: 4px;
  left: 4px;
  bottom: 0px;
}
.card-label {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: 8px;
  top: calc(50% - 12px);
}
.icon2 {
  flex-shrink: 0;
  position: relative;
  transform: translate(-24px, 0px);
  overflow: visible;
}
.icon4 {
  flex-shrink: 0;
  position: relative;
  overflow: visible;
}
.title {
  color: var(--element-neutral-color, rgba(0, 0, 0, 0.59));
  text-align: left;
  font: var(--ui-overline, 600 12px/24px "Noto Sans", sans-serif);
  text-transform: uppercase;
  position: relative;
}
.content-area {
  padding: 8px 16px 8px 16px;
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex: 1;
  position: relative;
  overflow: hidden;
}
.content-frame {
  align-self: stretch;
  flex: 1;
  position: relative;
  overflow: hidden;
}
.label {
  color: var(--element-active-color, #1a1a1a);
  text-align: left;
  font: var(--ui-label, 400 12px/16px "Noto Sans", sans-serif);
  position: relative;
}